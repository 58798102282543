<template>
  <div class="q-pa-sm">
    <div class="main-content q-pa-md">
      <Mural :breadcrumbs="breadcrumbs" route="users" :query="{}">
        <DefaultBtn titleButton="Importar" icon="save" @click="importUsers" />
      </Mural>
      <form>
        <div class="column q-mt-md">
          <div>
            <label for="">Status do Usuário</label>
            <q-toggle
              v-model="formUsersData.status"
              :true-value="1"
              :false-value="0"
              color="green"
            />
          </div>
          <div>
            <label for="">E-mail válido</label>
            <q-toggle v-model="formUsersData.email_valid" color="green" />
          </div>
          <div>
            <label for="">É Líder</label>
            <q-toggle v-model="isLeader" color="green" />
          </div>
        </div>
        <div class="container-inputs q-mt-md">
          <div class="form-group">
            <label for="" class="label-input">Perfil</label>
            <q-select
              outlined
              color="default-pink"
              dense
              emit-value
              map-options
              v-model="formUsersData.role_id"
              :options="profiles"
              :rules="[(val) => !!val || 'O campo Perfil é obrigatório.']"
            />
          </div>
          <div class="form-group">
            <label for="">Líder </label>
            <q-select
              outlined
              color="default-pink"
              dense
              emit-value
              map-options
              use-input
              input-debounce="0"
              @filter="filterFnUsers"
              v-model="formUsersData.lider"
              :options="users"
            >
              <template v-slot:no-option>
                <q-item>
                  <q-item-section class="text-grey">
                    Não encontrado
                  </q-item-section>
                </q-item>
              </template>
            </q-select>
          </div>
        </div>
        <div class="container-input" v-if="isMaster">
          <label for="" class="label-input">Empresa</label>
          <q-select
            outlined
            color="default-pink"
            dense
            emit-value
            map-options
            v-model="formUsersData.company_Id"
            :options="companies"
            @update:model-value="filterGroupByCompany()"
            :rules="[(val) => !!val || 'O campo empresa é obrigatório.']"
          >
            <template v-slot:no-option>
              <q-item>
                <q-item-section class="text-grey">
                  Não encontrado
                </q-item-section>
              </q-item>
            </template>
          </q-select>
        </div>
        <div class="container-user-groups">
          <div class="item-user-groups q-mb-md">
            <label for="">Selecione um segmento</label>
            <q-select
              outlined
              color="default-pink"
              dense
              multiple
              use-chips
              use-input
              emit-value
              map-options
              @filter="filterFnBranches"
              v-model="branches"
              :options="listBranches"
            >
              <template v-slot:no-option>
                <q-item>
                  <q-item-section class="text-grey">
                    Não encontrado
                  </q-item-section>
                </q-item>
              </template>
            </q-select>
          </div>
          <div class="container-groups">
            <div class="item-groups item-user-groups">
              <label for="" class="label-input">Grupos</label>
              <q-select
                v-model="formUsersData.groups"
                :options="_optionListGroups"
                @filter="filterFnGroups"
                outlined
                color="default-pink"
                dense
                multiple
                :disable="
                  isMaster && formUsersData.company_Id == null ? true : false
                "
                use-chips
                use-input
                emit-value
                map-options
                :rules="[
                  (val) => !val.length == 0 || 'O campo grupos é obrigatório',
                ]"
                ><template v-slot:no-option>
                  <q-item>
                    <q-item-section class="text-grey">
                      Não encontrado
                    </q-item-section>
                  </q-item>
                </template></q-select
              >
            </div>
            <q-checkbox
              left-label
              v-model="allGroups"
              label="Selecionar todos"
              color="default-pink"
              @update:model-value="selectedFilteredGroups"
            />
          </div>
        </div>
        <q-separator spaced inset />
        <div class="row">
          <div class="col-6">
            <div>
              <label class="label-input">Arquivo de Importação</label>
            </div>
            <div class="col-11" style="max-width: 92%">
              <q-file
                outlined
                dense
                bottom-slots
                accept="text/csv"
                v-model="file"
                @update:model-value="(value) => onEventFilePicked(value)"
                @rejected="onRejected"
                label="Arquivo para Importação"
                counter
                color="default-pink"
              >
                <template v-slot:prepend>
                  <q-icon name="cloud_upload" @click.stop />
                </template>
                <template v-slot:append>
                  <q-icon
                    name="close"
                    @click.stop="model = null"
                    class="cursor-pointer"
                  />
                </template>

                <template v-slot:hint>
                  <a
                    @click="downloadFile"
                    style="font-size: 14px; color: #0000EE; cursor: pointer; text-decoration: underline;"
                    >Baixar Modelo de Importação</a
                  >
                </template>

              </q-file>
              <div class="q-mt-md container-observation">
                <h3>Orientações para o arquivo de importação em lote:</h3>
                <ol>
                  <li>Todos os campos são obrigatórios.</li>
                  <li>
                    Verificar se todos os campos obrigatórios estão preenchidos.
                  </li>
                  <li>
                    As informações dos campos <strong>first_name</strong>,
                    <strong>last_name</strong> devem estar em campos separados.
                  </li>
                  <li>
                    O campo <strong>first_name</strong> deverá ser preenchido
                    somente com o primeiro nome. (Em caso de nome composto
                    preencher somente com o primeiro nome)
                  </li>
                  <li>
                    O campo <strong>last_name</strong> deverá ser preenchido com
                    o restante do nome completo.
                  </li>
                  <li>
                    O arquivo deverá ser em formato  <strong>csv</strong> e separado com <strong>(;)</strong>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <q-inner-loading
    :showing="loadingPage"
    label="Por favor aguarde..."
    label-class="text-teal"
    label-style="font-size: 1.1em"
  />
</template>

<script>
import { ref, reactive, onMounted, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useQuasar } from "quasar";
import { useStore } from "vuex";

import Mural from "@/components/Mural.vue";
import DefaultBtn from "@/components/shared/buttons/DefaultBtn.vue";
import GroupServices from "@/services/GroupServices";
import UserServices from "@/services/UserServices";
import RoleServices from "@/services/RoleServices";
import CompanyServices from "@/services/CompanyServices";
import { ENV } from "@/utils/env";

export default {
  name: "BatchRegistrationUsers",
  components: {
    Mural,
    DefaultBtn,
  },
  setup() {
    let _roleService = new RoleServices();
    let _groupService = new GroupServices();
    let _userService = new UserServices();
    let _companyServices = new CompanyServices();
    let $q = new useQuasar();
    const store = new useStore();

    let router = new useRouter();
    let route = new useRoute();
    let urlAwsBucket = ENV.URL_AWS_BUCKET;
    let breadcrumbs = ref(["Cadastro em lote"]);
    const refFileInput = ref(null);
    let file = ref(null);
    let loadingPage = ref(false);
    let status = ref(false);
    let profiles = ref([]);
    let companies = ref([]);
    let listGroups = ref([]);
    let _optionListGroups = ref([]);
    let _option_groups_company = ref([]);
    let listBranches = ref([]);
    let optionListBranches = ref([]);
    let users = ref([]);
    let _option_users = ref([]);

    let branches = ref([]);
    let formUsersData = reactive({
      groups: [],
      role_id: null,
      lider: null,
      status: 0,
      company_Id: null,
      email_valid: true,
      userDatas: [],
    });
    let userData = ref(null);
    let isMaster = ref(false);
    const isLeader = ref(false);
    const allGroups = ref(false);

    async function _getAllRoles() {
      await _roleService.showRoles().then((response) => {
        response.data.map((item) => {
          profiles.value.push({
            label: item.name,
            value: item.id,
          });
        });
      });
    }

    async function _getAllUsers() {
      loadingPage.value = true;
      await _userService
        .getAllUser()
        .then((response) => {
          response.data.map((item) => {
            if (item.is_leader) {
              users.value.push({
                label: item.first_name + " " + item.last_name,
                value: item.id,
              });
            }
          });
          _option_users.value = users.value;
          loadingPage.value = false;
        })

        .catch((e) => {
          console.log("deu ruim " + e);
        });
    }

    async function _getAllCompanies() {
      loadingPage.value = true;
      await _companyServices
        .getAll()
        .then((response) => {
          response.data.map((item) => {
            companies.value.push({
              label: item.company_name,
              value: item.id,
            });
          });
          loadingPage.value = false;
        })

        .catch((e) => {
          console.log("deu ruim " + e);
        });
    }

    async function getBranches() {
      await _groupService
        .listBranches()
        .then((response) => {
          response.data.map((item) => {
            if (item.status) {
              listBranches.value.push({
                label: item.name_branch,
                value: item.id,
              });
            }
          });
          optionListBranches.value = listBranches.value;
        })
        .catch((err) => {
          console.log(err);
        });
    }

    async function _getAllGroups() {
      loadingPage.value = true;
      _groupService
        .listGroups()
        .then((response) => {
          response.data.map((item) => {
            listGroups.value.push({
              label: item.group_name,
              value: item.id,
              company_id: item.company_id,
              branch_id: item.branch_id,
            });
          });
          _optionListGroups.value = listGroups.value;
          _option_groups_company.value = listGroups.value;
          loadingPage.value = false;
        })

        .catch((e) => {
          console.log("deu ruim " + e);
        });
    }

    const sendEventAddImage = () => {
      refFileInput.value.click();
    };

    function handleNotificationError(caption) {
      $q.notify({
        message:
          "Por favor verifique o arquivo inserido e corrija o erro informado!",
        caption: caption,
        color: "red-9",
        position: "top",
        timeout: 10000,
      });

      setTimeout(() => {
        router.go();
      }, 10000);

      formUsersData.userDatas = [];
    }

    function onRejected() {
      $q.notify({
        message: "Por favor adicione um arquivo válido",
        caption: "Arquivos .csv",
        color: "red-9",
        position: "top",
        timeout: 1500,
      });
    }

    async function onEventFilePicked(file) {
      const Papa = require("papaparse");

      if (!(file instanceof Blob)) {
    return;
  }

Papa?.parse(file, {
  header: true,
  delimiter: ";",
  complete: (results) => {
    formUsersData.userDatas = results?.data.map((user) => {
      user.is_leader = isLeader.value;
      return user;
    });
    formUsersData.userDatas.pop()
  },



  error: (results, file) => {
    $q.notify({
      message: results?.error?.message,
      type: "negative",
      position: "top",
      timeout: 1500,
    });
  },  
});
    }

    function filterFnUsers(val, update) {
      if (val === "") {
        update(() => {
          users.value = _option_users.value;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        users.value = _option_users.value.filter(
          (v) => v.label.toLowerCase().indexOf(needle) > -1
        );
      });
    }

    function filterGroupByCompany() {
      _optionListGroups.value = null;
      listBranches.value = null;
      _filterGroupsWithCompanies();
    }

    function _filterGroupsWithCompanies() {
      listGroups.value = _option_groups_company.value.filter(
        (v) => v.company_id == formUsersData.company_Id
      );

      optionListBranches.value = optionListBranches.value.filter(
        (branch) => branch.company_id == formUsersData.company_Id
      );

      _optionListGroups.value = listGroups.value;
      listBranches.value = optionListBranches.value;
    }

    function filterFnGroups(val, update) {
      if (val === "") {
        update(() => {
          if (branches.value.length > 0) {
            _optionListGroups.value = listGroups.value.filter((group) => {
              return branches.value.includes(group.branch_id);
            });
          }
          _optionListGroups.value = listGroups.value;
        });
      }

      update(() => {
        const needle = val.toLowerCase();
        _optionListGroups.value = _optionListGroups.value.filter((v) => {
          if (branches.value.length > 0) {
            return (
              v.label.toLowerCase().indexOf(needle) > -1 &&
              branches.value.includes(v.branch_id)
            );
          }
          return v.label.toLowerCase().indexOf(needle) > -1;
        });
      });
    }

    function filterFnBranches(val, update) {
      if (val === "") {
        update(() => {
          listBranches.value = optionListBranches.value;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        listBranches.value = optionListBranches.value.filter(
          (v) => v.label.toLowerCase().indexOf(needle) > -1
        );
      });
    }

    async function importUsers() {
      loadingPage.value = true;

      if (formUsersData.lider == null) {
        formUsersData.lider = 0;
      }

      if (
        formUsersData.groups == [] ||
        formUsersData.role_id == null ||
        formUsersData.company_Id == null ||
        formUsersData.email_valid == null ||
        formUsersData.userDatas.length === 0
      ) {
        $q.notify({
          message: "Preencha todos os campos obrigatórios",
          color: "red-9",
          position: "top",
        });
        loadingPage.value = false;

        return;
      }

      formUsersData.userDatas.forEach((userData) => {
        userData.is_leader = isLeader.value;
      });

      await _userService
        .importUser(formUsersData)
        .then((response) => {
          loadingPage.value = false;
          $q.notify({
            message: "Usuários salvos com sucesso",
            color: "green-9",
            position: "top",
          });
          formUsersData.lider = null;
        })
        .catch((error) => {
          $q.notify({
            message: "Erro ao salvar os usuários",
            color: "red-9",
            position: "top",
          });
          formUsersData.lider = null;
          loadingPage.value = false;
        });
    }

    function selectedFilteredGroups() {
      if (allGroups.value) {
        _optionListGroups.value.filter((group) => {
          if (!formUsersData.groups.includes(group.value)) {
            formUsersData.groups.push(group.value);
          }
        });
      } else {
        formUsersData.groups = null;
        formUsersData.groups = [];
      }
    }

    onMounted(async () => {
      userData.value = JSON.parse(localStorage.getItem("vuex")).userdata;
      isMaster.value = userData.value.master == 1;
      if (!isMaster.value) {
        formUsersData.company_Id =
          formUsersData.company_Id ?? store.state.userdata.company_id;
      }
      await _getAllUsers();
      await _getAllCompanies();
      await _getAllGroups();
      await _getAllRoles();
      getBranches();
    });

    watch(branches, (newBranch, oldBranch) => {
      if (newBranch.length == 0) {
        formUsersData.groups = null;
        formUsersData.groups = [];
        allGroups.value = false;
      }

      if (newBranch.length != oldBranch.length) {
        _optionListGroups.value = listGroups.value;
      }

      if (newBranch.length != 0) {
        _optionListGroups.value = listGroups.value.filter((group) => {
          return newBranch.includes(group.branch_id);
        });

        let groupValues = _optionListGroups.value.map((group) => {
          return group.value;
        });

        formUsersData.groups = groupValues.filter((value) => {
          if (formUsersData.groups.includes(value)) {
            return value;
          }
        });
      }
    });

    watch(
      () => formUsersData.groups,
      (newGroup, oldGroup) => {
        if (newGroup.length == 0) {
          allGroups.value = false;
        }
      }
    );

    watch(_optionListGroups, (newGroup, oldGroup) => {
      if (allGroups.value && branches.value.length != 0) {
        if (newGroup.length > oldGroup.length) {
          _optionListGroups.value.filter((group) => {
            if (!formUsersData.groups.includes(group.value)) {
              formUsersData.groups.push(group.value);
            }
          });
        }
      }
    });

    const downloadFile = () => {
      const link = document.createElement('a');
      link.href = `${urlAwsBucket}files/model_massive_import_user.csv`;
      link.download = 'model_massive_import_user.csv';
      link.click();
    };

    return {
      breadcrumbs,
      refFileInput,
      sendEventAddImage,
      onEventFilePicked,
      onRejected,
      urlAwsBucket,
      file,
      formUsersData,
      isMaster,
      loadingPage,
      status,
      profiles,
      users,
      companies,
      filterFnUsers,
      filterFnGroups,
      filterGroupByCompany,
      importUsers,
      isLeader,
      allGroups,
      listBranches,
      selectedFilteredGroups,
      filterFnBranches,
      _optionListGroups,
      branches,
      downloadFile,
    };
  },
};
</script>

<style lang="scss" scoped>
.import-file {
  display: flex;
  justify-content: center;
}

:is(.label-input, label) {
  color: #666666;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.25px;
  margin-bottom: 4px;
}

.label-input::after {
  content: " *";
  color: #cc0000;
}
.container-inputs {
  display: flex;
  justify-content: space-between;
}

.form-group {
  width: calc(50% - 24px);
}

.container-groups {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.item-groups {
  width: calc(80% - 10px);
}

.container-user-groups {
  display: flex;
  flex-direction: column;
}

.container-observation {
  h3 {
    font-size: 17.6px;
    text-wrap: nowrap;
  }
}
</style>
